import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const Container = styled.div`
    display: flex;
    text-decoration: none;
    border-radius: 6px;

    .tag{
        text-decoration: none;
    }

    .divider {
            background-color: black;
            margin-right: 20px;
            opacity: 0.2;
            margin-left: 20px;
            margin-bottom: 20px;
        }
    
    
`;
export const Card = styled.li`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 280px;
    width: 100%;
    box-shadow: 0px 3px 4px #0000001F;
    border-radius: 5px;
    opacity: 1;
    list-style: none;
    cursor: pointer;
    transition: all .3s;
     &:hover{
         opacity: 0.8;
         transform: translateY(-10px);
     }
    
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        padding-right: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #BABABA;
        border-radius: 5px;
    }
`;
export const Title = styled.div`
    background: #FDFDFD 0% 0% no-repeat padding-box;
    align-items: center;
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #E9EDF2;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
`;
export const Name = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    width: 90%;
`;
export const H2 = styled.h2`
    text-align: left;
    font: normal normal bold 0.9rem Mulish;
    letter-spacing: 0px;
    color: #0080FC;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const Graphic = styled.div`
    padding-top: 10px;
    align-items:center;
    text-align: center;
    height: 80%;
    width: 100%;
   
`;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingRight: '50px',
        },
        root: {
            height: '90%',
            width: '100%',
            backgroundColor: '#ffffff',
            borderRadius: '6px',
        },
        TabPanelStyle: {
            /* backgroundColor: '#E9EDF2' */
        },
        AppBarStyle: {
            backgroundColor: '#FFFFFF',
            textAlign: 'left',
            alignItems: 'left',
            paddingLeft: '20px',
            color: 'black',
            boxShadow: '0 0px 0px 0px',
            borderRadius: '6px',
        },

        TabFont: {
            fontFamily: 'Mulish',
            color: '#434343',
        },

        SelectedTab: {
            "& .Mui-selected": {
                color: '#0080FC !important',
                fontWeight: 'bold',
            }
        },
        title: {
            paddingTop: '20px',
            paddingLeft: '30px',
            paddingBottom: '10px'
        },
        title_h5: {
            font: '800 1.2rem Mulish',
            color: '#434343',
            opacity: '1',
            textAlign: 'left',
            letterSpacing: '0px',


        }
    }),
);

export const TabArea = styled.div`
    height: 80%;
    overflow-y: auto;
        ::-webkit-scrollbar {
            width: 8px;
        }

        ::-webkit-scrollbar-track {
            padding-right: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #E3E3E3;
            border-radius: 5px;
        }
`;

export const BtnAdd = styled.div`
    .addButton {
        width: 150px;

        .addIcon{
            padding-right: 5px;
        }
    }
`;

export const SearchAddButtons = styled.div`
    display: flex;
    margin-right: 20px;
    justify-content: space-between;

    @media(max-width: 900px){
        padding-top: 15px;
        margin-right: 10px;
    }
`;

export const SearchInput = styled.div`
    display: flex;
    flex-direction: row;
    .input-group{
        .input-group-text{
            background-color: #FFFFFF;
            border-right: 0;
        }

        .form-control{
            border-left: 0;
        }
    }

    @media(max-width: 900px){
        width: 50%;
        margin-left: 10px;
    }
`;

export const NoArchivedCampaign = styled.div`
    width: 100%;
    height: 100%;
    margin-top: 5vh;
    background: #FFFFFF 0% 0% no-repeat padding-box;
`;

export const SubTitle = styled.div`
    text-align: left;
    font: normal normal 600 15px/22px Mulish;
    letter-spacing: 0px;
    color: #787878;
    opacity: 1;
    padding-left: 20px;
    margin: auto 0;
`;

export const CampaingOptionsButton = styled.div`
    border: none;
    padding-right: 1rem;
    display: flex;
`;

export const OptionsMenu = styled.div`
    :hover{
        color: #0080fc;
    };
`;


export const OptionsButton = styled.div`
    border: none;
    display: flex;
`;

export const TitleCampaigH2 = styled.h2`
    text-align: left;
    font: normal normal bold 0.9rem Mulish;
    letter-spacing: 0px;
    color: #0080FC;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 2rem;
`;

export const ModalDuplicate = styled.div`
    display: flex;
    min-width: 34rem;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 10px;
    background: #F3F7FF;

    .containerModal{
        max-width: 34rem;
    };
    h6{
        color: #434343;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 700;
    };
    p{
        color: #4D5D71;
        font-family: Mulish;
        font-size: 16px;
        font-weight: 600;
    };
    .contentButtons{
        display: flex;
        justify-content: flex-end;
    };
    .buttonCancel{
        padding: 8px;
        margin-right: 6px;
        color: #486688;
        background: none;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-transform: uppercase;
        border: none;
    };
    .buttonCancel:hover{
        background-color: #E0E7EF;
    };
    .buttonConfirm{
        padding: 8px;
        color:#fff;
        background: #0080fc;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-transform: uppercase;
        border: none;
    };
    .buttonCancel:focus,
    .buttonConfirm:focus{
        box-shadow: none;
    };
    .contentInputDate{
        background-color: #fff;
        padding: 3rem 1rem 3rem 1rem;
        margin-bottom: 1rem;
    };
`;
export const LabelDate = styled.h5`
    color: #6E6E6E;
    font-size: 16px;
    font-weight: 600;
`;